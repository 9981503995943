<template>
  <div class="px-3 mt-5">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="row" @submit.prevent="handleSubmit(submit)">
        <div class="col-sm-12 text-start">
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="userName" class="text-muted mb-2">使用者名稱</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="text" class="form-control" id="userName"
                    v-model="user.userName"
                    placeholder="使用者名稱">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="account" class="text-muted mb-2">帳號</label>
              <input type="text" class="form-control" id="account"
                v-model="user.account"
                placeholder="帳號"
                readonly>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="email" class="text-muted mb-2">Email</label>
              <ValidationProvider rules='email' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="email" class="form-control" id="email"
                    v-model="user.email"
                    placeholder="email"
                  >
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="walletAddress" class="text-muted mb-2">錢包位址</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="text" class="form-control" id="walletAddress"
                    v-model="user.walletAddress"
                    placeholder="位址"
                  >
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="role" class="text-muted mb-2">身份</label>
              <div class="d-flex mt-1 justify-content-between justify-content-md-start">
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" id="Teacher" value="Teacher" v-model="user.role" />
                  <label for="Teacher" class="form-check-label mt-1">課程教師</label>
                </div>
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" id="Admin" value="Admin" v-model="user.role" />
                  <label for="Admin" class="form-check-label mt-1">系統管理者</label>
                </div>
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" id="Member" value="Member" v-model="user.role" />
                  <label for="Member" class="form-check-label mt-1">一般會員</label>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="loginProvider" class="text-muted mb-2">登入方式</label>
              <input type="text" class="form-control" id="loginProvider"
                v-model="user.loginProvider"
                placeholder="位址"
                readonly>
            </div>

          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="emailConfirmed" class="text-muted mb-2">身份</label>
              <div class="d-flex mt-1">
                <div class="form-check ps-2 me-3" v-if="user.emailConfirmed">
                  <input class="form-check-input d-none" type="radio" id="confirmed" value="true" v-model="user.emailConfirmed" />
                  <label for="confirmed" class="form-check-label mt-1"><i class="bi bi-check-lg me-1 text-success"></i>已驗證</label>
                </div>
                <div class="form-check ps-2 me-3" v-if="!user.emailConfirmed">
                  <input class="form-check-input d-none" type="radio" id="unconfirmed" value="false" v-model="user.emailConfirmed"/>
                  <label for="unconfirmed" class="form-check-label mt-1"><i class="bi bi-exclamation-lg me-1 text-success"></i>未驗證</label>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="lockout" class="text-muted mb-2">使用者鎖定</label>
              <div class="form-check mt-1">
                <input class="form-check-input" type="checkbox"
                  v-model="user.lockout"
                  id="is_enabled">
                <label class="form-check-label mt-1" for="is_enabled">
                  鎖定
                </label>
              </div>
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="password" class="text-muted mb-2" @click="togglePsd = !togglePsd" style="cursor: pointer;">
                修改密碼<i class="bi bi-chevron-down d-inline-block" :class="{'rotate': togglePsd }"></i>
              </label>
              <div class="mt-1" v-if="togglePsd">
                <input type="text" class="form-control" id="password"
                  v-model="psd"
                  placeholder=""
                  @change="checkPsd"
                >
                <span class="text-danger mt-1" style="font-size: 12px;">{{ errorPsd }}</span>
              </div>
            </div>
            <div class="col-md-12 text-end">
              <button class="btn btn-main" type="submit">儲存</button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__User',
  data() {
    return {
      userId: '',
      userName: '',
      account: '',
      email: '',
      role: '',
      is_enabled: true,
      togglePsd: false,
      psd: '',
      errorPsd: '',
      user: {
        account: '',
        userName: '',
        role: '',
        email: '',
        walletAddress: '',
        loginProvider: '',
        emailConfirmed: true,
        lockout: false,
      },
      metaTitle: '使用者資訊 / 使用者 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "使用者資訊 / 使用者 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '使用者資訊 / 使用者 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Member/Management/${this.userId}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data.user);
        this.updateLoading(false);
        this.user = res.data.data.user;
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          } else if (err.response.status == 404) {
            Swal.fire(
              '',
              '找不到該使用者',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }

        this.$log.debug(err.response);
      });
    },
    submit() {
      const vm = this;
      const data = {
        email: this.user.email,
        userName: this.user.userName,
        role: this.user.role,
        walletAddress: this.user.walletAddress == 'NotSet' ? '' : this.user.walletAddress,
        emailConfirmed: this.user.emailConfirmed,
        lockout: this.user.lockout,
        password: this.psd,
      };
      this.updateLoading(true);
      vm.$http.put(`${this.$API_PATH}/Admin/Member/Management/${this.userId}`, data, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        Swal.fire(
          '儲存成功',
          `${res.data.message}`,
          'success',
        );
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          } else if (err.response.status == 404) {
            Swal.fire(
              '',
              '找不到該使用者',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    checkPsd() {
      if (this.togglePsd) {
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,}$/.test(this.psd) == false) {
          this.errorPsd = '請輸入6位數以上的密碼(內含大小寫字母及數字)';
        } else {
          this.errorPsd = '';
        }
      }
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider']),
  },
  created() {
    this.userId = this.$route.params.id;
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
</style>
